var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","px-11":""}},[_c('v-pagination',{staticClass:"mt-5",attrs:{"total-visible":"6","length":_vm.pagination.pageCount},on:{"input":_vm.goToPage,"next":_vm.nextPage,"previous":_vm.previousPage},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.properties,"item-text":"text","item-value":"value","label":"Select"},model:{value:(_vm.search.property),callback:function ($$v) {_vm.$set(_vm.search, "property", $$v)},expression:"search.property"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mr-6",attrs:{"prepend-inner-icon":"search","label":"Search"},model:{value:(_vm.search.text),callback:function ($$v) {_vm.$set(_vm.search, "text", $$v)},expression:"search.text"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.searchUsers(0)}}},[_vm._v("Search")]),(_vm.search.active)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"darkGrey","text":""},on:{"click":function($event){_vm.getLogs(1, _vm.pagination.limit); _vm.search.active = false;_vm.search.text = ''}}},[_vm._v("Clear")]):_vm._e()],1)],1),_c('v-skeleton-loader',{attrs:{"type":"table","loading":_vm.pageLoading,"transition":"slide-y-transition"}},[_c('v-data-table',{staticClass:"background mr-6",attrs:{"hide-default-footer":"","items-per-page":75,"headers":_vm.headers,"items":_vm.logs},scopedSlots:_vm._u([{key:"item.location",fn:function(ref){
var item = ref.item;
return [(item.login_city)?_c('div',[_c('span',{staticClass:"flag-icon mr-2",class:("flag-icon-" + (item.login_country.toLowerCase()))}),_vm._v(" "+_vm._s(item.login_city)+", "+_vm._s(item.login_region)+", "+_vm._s(item.login_country)+" ")]):_c('div',[_c('span',{staticClass:"caption font-italic grey--text text--darken-1"},[_vm._v("Data Unavailable")])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" "),_c('br'),_c('span',{staticClass:"font-weight-medium"},[_vm._v("APP:")]),(item.login_client)?_c('span',[_vm._v(_vm._s(item.login_client))]):_c('span',{staticClass:"font-italic grey--text text--darken-1 ml-2"},[_vm._v("Data Unavailable")])]}},{key:"item.login_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.login_date)+" at "),(item.login_time)?_c('span',[_vm._v(_vm._s(item.login_time))]):_c('span',{staticClass:"caption font-italic grey--text text--darken-1"},[_vm._v("Data Unavailable")])]}},{key:"item.logout_date",fn:function(ref){
var item = ref.item;
return [(item.logout_date)?_c('span',[_vm._v(_vm._s(item.logout_date))]):_vm._e(),(item.logout_time)?_c('span',[_vm._v(" at ")]):_vm._e(),(item.logout_time)?_c('span',[_vm._v(_vm._s(item.logout_time))]):_c('span',{staticClass:"caption grey--text text--darken-1"},[_vm._v("Currently Active")])]}}])})],1),(_vm.deleteDialog.status)?_c('DeleteModal',{attrs:{"title":_vm.deleteDialog.title,"status":_vm.deleteDialog.status,"description":_vm.deleteDialog.description,"callback":_vm.deleteDialog.callback},on:{"success":function($event){_vm.snackbar({text: 'Invitation cancelled successfully.'}), _vm.getAllOpenInvitations()},"close":function($event){_vm.deleteDialog.status = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }