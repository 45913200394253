<template>
  <v-container fluid px-11>
    <v-pagination
      class="mt-5"
      @input="goToPage"
      @next="nextPage"
      @previous="previousPage"
      total-visible="6"
      v-model="pagination.page"
      :length="pagination.pageCount"
    ></v-pagination>
    <v-row>
      <v-col cols="12" md="3">
        <v-select
          v-model="search.property"
          :items="properties"
          item-text="text"
          item-value="value"
          label="Select"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field class="mr-6" prepend-inner-icon="search" label="Search" v-model="search.text"></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn color="primary" @click="searchUsers(0)">Search</v-btn>
        <v-btn
          v-if="search.active"
          class="ml-2"
          color="darkGrey"
          text
          @click="getLogs(1, pagination.limit); search.active = false;search.text = ''"
        >Clear</v-btn>
      </v-col>
    </v-row>
    <v-skeleton-loader type="table" :loading="pageLoading" transition="slide-y-transition">
      <v-data-table
        hide-default-footer
        :items-per-page="75"
        class="background mr-6"
        :headers="headers"
        :items="logs"
      >
        <template v-slot:item.location="{ item }">
          <div v-if="item.login_city">
            <span class="flag-icon mr-2" :class="`flag-icon-${item.login_country.toLowerCase()}`"></span>
            {{item.login_city}}, {{item.login_region}}, {{item.login_country}}
          </div>
          <div v-else>
            <span class="caption font-italic grey--text text--darken-1">Data Unavailable</span>
          </div>
        </template>
        <template v-slot:item.email="{ item }">
          {{item.email}}
          <br />
          <span class="font-weight-medium">APP:</span>
          <span v-if="item.login_client">{{item.login_client}}</span>
          <span v-else class="font-italic grey--text text--darken-1 ml-2">Data Unavailable</span>
        </template>
        <template v-slot:item.login_date="{ item }">
          {{item.login_date}} at
          <span v-if="item.login_time">{{item.login_time}}</span>
          <span v-else class="caption font-italic grey--text text--darken-1">Data Unavailable</span>
        </template>
        <template v-slot:item.logout_date="{ item }">
          <span v-if="item.logout_date">{{item.logout_date}}</span>
          <span v-if="item.logout_time"> at </span>
          <span v-if="item.logout_time">{{item.logout_time}}</span>
          <span v-else class="caption grey--text text--darken-1">Currently Active</span>
        </template>
      </v-data-table>
    </v-skeleton-loader>
    <DeleteModal
      v-if="deleteDialog.status"
      :title="deleteDialog.title"
      :status="deleteDialog.status"
      :description="deleteDialog.description"
      :callback="deleteDialog.callback"
      @success="snackbar({text: 'Invitation cancelled successfully.'}), getAllOpenInvitations()"
      @close="deleteDialog.status = false"
    />
  </v-container>
</template>

<script>
import { AuthAPI } from '@/clients/auth/auth.api'
import { mapGetters } from 'vuex'

export default {
  mounted() {
    this.getLogs(this.pagination.page, this.pagination.limit)
  },
  components: {
    DeleteModal: () => import('@/components/custom-components/DeleteModal')
  },
  data() {
    return {
      search: {
        property: 'email',
        text: '',
        active: false
      },
      logs: [],
      headers: [
        {
          text: 'Email',
          sortable: true,
          value: 'email'
        }, {
          text: 'Login Date',
          sortable: true,
          value: 'login_date'
        }, {
          text: 'Logout Date',
          sortable: true,
          value: 'logout_date'
        },
        {
          text: 'Location',
          sortable: false,
          value: 'location'
        },
        {
          text: 'Login IP',
          sortable: true,
          value: 'login_ip'
        }
      ],
      properties: [
        { value: 'email', text: 'Email' }, { value: 'first_name', text: 'First Name' }, { value: 'last_name', text: 'Last Name' }, { value: 'phone', text: 'Phone' }
      ],
      pagination:
      {
        page: 1,
        pageCount: 0,
        limit: 75
      },
      pageLoading: false,
      deleteDialog: {
        status: false,
        title: '',
        description: 'This will cancel the invitation and you will have to re-invite the user again. Proceed?',
        callback: null
      }
    }
  },
  methods: {
    async getLogs(page, limit, data) {
      this.pageLoading = true
      let response
      try {
        if (data) {
          response = await AuthAPI.getLogs({ page, limit, data })
        } else {
          response = await AuthAPI.getLogs({ page, limit })
        }
      } catch (error) {
        this.pageLoading = false
        return console.log(error)
      }
      this.logs = response.data.payload.login_logs
      this.pagination.page = response.data.payload.current_page
      console.log(response.data.payload.current_page)
      this.pagination.pageCount = response.data.payload.total_pages
      this.pageLoading = false
    },
    goToPage(page) {
      if (this.search.active) {
        this.searchUsers(this.pagination.page)
      } else {
        this.getLogs(page, this.pagination.limit)
      }
    },
    nextPage() {
      if (this.search.active) {
        if (this.pagination.page < this.pagination.length) {
          this.searchUsers(this.pagination.page)
        }
      } else {
        if (this.pagination.page < this.pagination.length) {
          this.goToPage(this.pagination.page)
        }
      }
    },
    previousPage() {
      if (this.search.active) {
        if (this.pagination.page >= 1) {
          this.searchUsers(this.pagination.page)
        }
      } else {
        if (this.pagination.page >= 1) {
          this.goToPage(this.pagination.page)
        }
      }
    },
    async searchUsers(page) {
      let text = this.search.text
      if (this.search.text.indexOf(',') > -1) {
        text = this.search.text.split(',')
      } else {
        text = [text]
      }
      let data = { property: this.search.property, values: text }
      this.getLogs(page, this.pagination.limit, data)
      this.search.active = true
    }
  },
  computed: {
    ...mapGetters({
      vg_activeBusiness: 'auth/activeBusiness'
    })
  }

}
</script>

<style lang="scss" scoped>
</style>
